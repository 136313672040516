import { useTranslation } from 'gatsby-plugin-react-i18next'
import React, { useEffect, useState } from 'react'

import { SCROLL_ANIMATION_DEFAULT_PROPS } from '../../../constants'
import useHomepage from '../../../hooks/useHomepage'
import Icon from '../../../images/icons/reason-icon-support.svg'
import Image from '../../../images/reason-image-support.png'
import UtmContent from '../../../models/UtmContent'
import { UrlService } from '../../../services'
import ScrollAnimation from '../../scroll-animation/Container'
import './reasons.less'

const Support = () => {
  const { t } = useTranslation()
  const { activeCityName } = useHomepage()
  const [url, setUrl] = useState('')

  useEffect(() => {
    const utmContent = new UtmContent({ position: 'reason-support' }).toString()
    setUrl(UrlService.signupUrl({ utmContent: utmContent, cityName: activeCityName }))
  }, [])

  return (
    <ScrollAnimation {...SCROLL_ANIMATION_DEFAULT_PROPS}>
      <div className="reason reason--support">
        <img src={Image} className="reason__image" />
        <div className="reason__wrapper">
          <ScrollAnimation {...SCROLL_ANIMATION_DEFAULT_PROPS}>
            <img src={Icon} className="reason__icon" />
          </ScrollAnimation>
          <ScrollAnimation {...SCROLL_ANIMATION_DEFAULT_PROPS}>
            <div className="reason__title">4. {t('Support restaurants')}</div>
          </ScrollAnimation>
          <ScrollAnimation {...SCROLL_ANIMATION_DEFAULT_PROPS}>
            <div className="reason__subtitle">
              <p>{t('Third party platforms charge restaurants large fees.')}</p>
              <p>{t('MealPal enables restaurants to optimize operations and increase profit.')}</p>
            </div>
          </ScrollAnimation>
          <ScrollAnimation {...SCROLL_ANIMATION_DEFAULT_PROPS}>
            <a href={url} className="reason__action red-cta">
              {t('Start saving on lunch today')}
            </a>
          </ScrollAnimation>
        </div>
      </div>
    </ScrollAnimation>
  )
}

export default Support
