/* eslint-disable react/no-unescaped-entities */
import { useTranslation } from 'gatsby-plugin-react-i18next'
import React, { useState } from 'react'

import { SCROLL_ANIMATION_DEFAULT_PROPS } from '../../../constants'
import useHomepage from '../../../hooks/useHomepage'
import UtmContent from '../../../models/UtmContent'
import { UrlService } from '../../../services'
import FaqQuestion from '../../FaqQuestion'
import ScrollAnimation from '../../scroll-animation/Container'
import './faqs.less'

const FAQs = () => {
  const { t } = useTranslation()
  const { activeCityName } = useHomepage()

  const buildurl = (section: string) => {
    const utmContent = new UtmContent({ position: section }).toString()
    return UrlService.signupUrl({ utmContent: utmContent, cityName: activeCityName })
  }

  const [open, setOpen] = useState('')

  const toggle = (identifier: string) => {
    setOpen(open === identifier ? '' : identifier)
  }

  return (
    <div className="faqs">
      <ScrollAnimation {...SCROLL_ANIMATION_DEFAULT_PROPS}>
        <h1 className="faqs__title">Frequently Asked Questions</h1>
      </ScrollAnimation>
      <ScrollAnimation {...SCROLL_ANIMATION_DEFAULT_PROPS}>
        <div className="faqs__wrapper">
          <FaqQuestion
            customClass="question-group--first"
            question="What is MealPal?"
            open={open === '#1'}
            toggle={() => toggle('#1')}
          >
            MealPal is the world's leading lunch pickup platform, offering meals from thousands of restaurants for as
            little as $6. MealPal lets you skip the line and save up to 50% on lunch. Over 40 million meals have been
            served through MealPal and consumers have saved over $100 million on lunch.
            <div className="faqs__button">
              <a className="red-cta red-cta-padding" href={buildurl('reason-faq-what-is-mp')}>
                {t('Join MealPal today')}
              </a>
            </div>
          </FaqQuestion>
          <FaqQuestion
            question="How much money can I save using MealPal?"
            open={open === '#2'}
            toggle={() => toggle('#2')}
          >
            The typical MealPal user saves more than $600 on lunch per year. Meals that usally retail for $12 to $20 are
            available on MealPal for as little as $6 per meal. Never pay full price for lunch ever again!
            <div className="faqs__button">
              <a className="red-cta red-cta-padding" href={buildurl('reason-faq-save-money')}>
                {t('Join MealPal today')}
              </a>
            </div>
          </FaqQuestion>
          <FaqQuestion question="Where can I use MealPal?" open={open === '#3'} toggle={() => toggle('#3')}>
            <p>
              You can get delicious meals at any participating restaurant in the MealPal network! We're currently
              partnered with over 5,000 restaurants in Auckland, Austin, Boston, Chicago, London, Los Angeles,
              Manchester, Melbourne, New York City, Philadelphia, Portland, San Francisco, Seattle, Singapore, Sydney,
              Toronto, and Washington D.C..
            </p>
            Check out what's near you! To see a map of all participating restaurants, go to{' '}
            <a
              target="_blank"
              href={`${process.env.GATSBY_LANDING_PAGE_URL}/#map`}
              rel="noreferrer"
            >{`${process.env.GATSBY_LANDING_PAGE_URL}/#map`}</a>
            .
            <div className="faqs__button">
              <a className="red-cta red-cta-padding" href={buildurl('reason-faq-where-use')}>
                {t('Join MealPal today')}
              </a>
            </div>
          </FaqQuestion>
          <FaqQuestion
            question="How does MealPal have such low prices?"
            open={open === '#4'}
            toggle={() => toggle('#4')}
          >
            Each restaurant on the MealPal platform offers one meal per day. This meal is the same quality and size as
            what the restaurant typically serves. MealPal uses technology and predictive analytics to build a more
            efficient ordering process for both consumers and restaurants, and is thus able to offer considerable
            savings on each meal.
            <div className="faqs__button">
              <a className="red-cta red-cta-padding" href={buildurl('reason-faq-low-prices')}>
                {t('Join MealPal today')}
              </a>
            </div>
          </FaqQuestion>
          <FaqQuestion question="Is Mealpal good for restaurants?" open={open === '#5'} toggle={() => toggle('#5')}>
            Yes! Unlike other meal services, MealPal doesn't charge restaurants fees. MealPal enables restaurants to
            optimize operations and increase profit by sending a large, planned daily order. We are committed to being
            the Most Valuable Partner our restaurants work with.
            <div className="faqs__button">
              <a className="red-cta red-cta-padding" href={buildurl('reason-faq-good-for-restaurants')}>
                {t('Join MealPal today')}
              </a>
            </div>
          </FaqQuestion>
          <FaqQuestion question="How does my plan work?" open={open === '#6'} toggle={() => toggle('#6')}>
            Your lunch subscription covers 100% of the cost of your lunch. Your MealPal cycle begins the day you sign up
            for a plan, and your cycle will renew every 30 days or when you have less than 10 credits remaining,
            whichever comes first. Join MealPal today and get lunch for just $1.99 per meal for your first week!
            <div className="faqs__button">
              <a className="red-cta red-cta-padding" href={buildurl('reason-faq-how-plan-works')}>
                {t('Join MealPal today')}
              </a>
            </div>
          </FaqQuestion>
        </div>
      </ScrollAnimation>
    </div>
  )
}

export default FAQs
