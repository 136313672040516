import { useTranslation } from 'gatsby-plugin-react-i18next'
import React, { useEffect, useState } from 'react'

import { SCROLL_ANIMATION_DEFAULT_PROPS } from '../../../constants'
import useHomepage from '../../../hooks/useHomepage'
import Icon from '../../../images/icons/reason-icon-enjoy.svg'
import Image from '../../../images/reason-image-enjoy.png'
import UtmContent from '../../../models/UtmContent'
import { UrlService } from '../../../services'
import ScrollAnimation from '../../scroll-animation/Container'
import './reasons.less'

const Enjoy = () => {
  const { t } = useTranslation()
  const { activeCityName } = useHomepage()
  const [url, setUrl] = useState('')

  useEffect(() => {
    const utmContent = new UtmContent({ position: 'reason-enjoy' }).toString()
    setUrl(UrlService.signupUrl({ utmContent: utmContent, cityName: activeCityName }))
  }, [])

  return (
    <ScrollAnimation {...SCROLL_ANIMATION_DEFAULT_PROPS}>
      <div className="reason reason--enjoy">
        <img src={Image} className="reason__image" />
        <div className="reason__wrapper">
          <ScrollAnimation {...SCROLL_ANIMATION_DEFAULT_PROPS}>
            <img src={Icon} className="reason__icon" />
          </ScrollAnimation>
          <ScrollAnimation {...SCROLL_ANIMATION_DEFAULT_PROPS}>
            <div className="reason__title">2. {t('Enjoy delicious meals everyday')}</div>
          </ScrollAnimation>
          <ScrollAnimation {...SCROLL_ANIMATION_DEFAULT_PROPS}>
            <div className="reason__subtitle">
              <p>{t('Wild arugula salad, pad thai, cheeseburger & fries, sushi, and more…')}</p>
              <p>
                {t('There’s')} <span>{t('thousands')}</span> {t('of delicious meals to choose from every week.')}
              </p>
            </div>
          </ScrollAnimation>
          <ScrollAnimation {...SCROLL_ANIMATION_DEFAULT_PROPS}>
            <a href={url} className="reason__action red-cta">
              {t('Start saving on lunch today')}
            </a>
          </ScrollAnimation>
        </div>
      </div>
    </ScrollAnimation>
  )
}

export default Enjoy
