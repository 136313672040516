import { useTranslation } from 'gatsby-plugin-react-i18next'
import React, { useEffect, useState } from 'react'

import { SCROLL_ANIMATION_DEFAULT_PROPS } from '../../../constants'
import useHomepage from '../../../hooks/useHomepage'
import Icon from '../../../images/icons/reason-icon-save-money.svg'
import Image from '../../../images/reason-image-save-money.png'
import UtmContent from '../../../models/UtmContent'
import { UrlService } from '../../../services'
import ScrollAnimation from '../../scroll-animation/Container'
import './reasons.less'

const SaveMoney = () => {
  const { t } = useTranslation()
  const { activeCityName } = useHomepage()
  const [url, setUrl] = useState('')

  useEffect(() => {
    const utmContent = new UtmContent({ position: 'reason-save-money' }).toString()
    setUrl(UrlService.signupUrl({ utmContent: utmContent, cityName: activeCityName }))
  }, [])

  return (
    <ScrollAnimation {...SCROLL_ANIMATION_DEFAULT_PROPS}>
      <div className="reason reason--save-money">
        <img src={Image} className="reason__image" />
        <div className="reason__wrapper">
          <ScrollAnimation {...SCROLL_ANIMATION_DEFAULT_PROPS}>
            <img src={Icon} className="reason__icon" />
          </ScrollAnimation>
          <ScrollAnimation {...SCROLL_ANIMATION_DEFAULT_PROPS}>
            <div className="reason__title">1. {t('Save money')}</div>
          </ScrollAnimation>
          <ScrollAnimation {...SCROLL_ANIMATION_DEFAULT_PROPS}>
            <div className="reason__subtitle">
              <p>{t('Stop over paying for lunch! With MealPal, you’ll get lunch for')}</p>
              <p>
                {t('under $6 and')} <span>{t('save up to 50%')}</span> {t('compared to the retail rate.')}
              </p>
            </div>
          </ScrollAnimation>
          <ScrollAnimation {...SCROLL_ANIMATION_DEFAULT_PROPS}>
            <a href={url} className="reason__action red-cta">
              {t('Start saving on lunch today')}
            </a>
          </ScrollAnimation>
        </div>
      </div>
    </ScrollAnimation>
  )
}

export default SaveMoney
