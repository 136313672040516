import { useTranslation } from 'gatsby-plugin-react-i18next'
import React, { useEffect, useState } from 'react'

import { SCROLL_ANIMATION_DEFAULT_PROPS } from '../../../constants'
import useHomepage from '../../../hooks/useHomepage'
import ImageMobile1 from '../../../images/hero-reasons-mobile-1.png'
import ImageMobile from '../../../images/hero-reasons-mobile.png'
import Image from '../../../images/hero-reasons.png'
import UtmContent from '../../../models/UtmContent'
import { UrlService } from '../../../services'
import ScrollAnimation from '../../scroll-animation/Container'
import './hero.less'

const Hero = () => {
  const { t } = useTranslation()
  const { activeCityName } = useHomepage()
  const [url, setUrl] = useState('')

  useEffect(() => {
    const utmContent = new UtmContent({ position: 'reasons-hero' }).toString()
    setUrl(UrlService.signupUrl({ utmContent: utmContent, cityName: activeCityName }))
  }, [])

  return (
    <ScrollAnimation {...SCROLL_ANIMATION_DEFAULT_PROPS}>
      <div className="reasons-hero">
        <img src={Image} className="reasons-hero__image" />
        <img src={ImageMobile} className="reasons-hero__image-mobile" />
        <img src={ImageMobile1} className="reasons-hero__image-mobile-1" />
        <div className="reasons-hero__wrapper">
          <ScrollAnimation {...SCROLL_ANIMATION_DEFAULT_PROPS}>
            <div className="reasons-hero__title">
              <span>{t('5 Reasons')}</span> {t('To try mealpal for lunch')}
            </div>
          </ScrollAnimation>
          <ScrollAnimation {...SCROLL_ANIMATION_DEFAULT_PROPS}>
            <div className="reasons-hero__subtitle">
              {t('Join today and get your first meal for')} <span>{t('$1.99')}</span>.<br />
              {t('Plus, get')} <span>{t('25% off')}</span> {t('your next 2 months.')}
            </div>
          </ScrollAnimation>
          <ScrollAnimation {...SCROLL_ANIMATION_DEFAULT_PROPS}>
            <a href={url} className="reasons-hero__action red-cta">
              {t('Start saving on lunch today')}
            </a>
          </ScrollAnimation>
        </div>
      </div>
    </ScrollAnimation>
  )
}

export default Hero
