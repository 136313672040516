import { useTranslation } from 'gatsby-plugin-react-i18next'
import React, { useEffect, useState } from 'react'

import { SCROLL_ANIMATION_DEFAULT_PROPS } from '../../../constants'
import useHomepage from '../../../hooks/useHomepage'
import Icon from '../../../images/icons/reason-icon-special-offer.svg'
import UtmContent from '../../../models/UtmContent'
import { UrlService } from '../../../services'
import ScrollAnimation from '../../scroll-animation/Container'
import './reasons.less'

const SpecialOffer = () => {
  const { t } = useTranslation()
  const { activeCityName } = useHomepage()
  const [url, setUrl] = useState('')

  useEffect(() => {
    const utmContent = new UtmContent({ position: 'reason-special-offer' }).toString()
    setUrl(UrlService.signupUrl({ utmContent: utmContent, cityName: activeCityName }))
  }, [])

  return (
    <ScrollAnimation {...SCROLL_ANIMATION_DEFAULT_PROPS}>
      <div className="reason reason--special-offer">
        <div className="reason__wrapper">
          <ScrollAnimation {...SCROLL_ANIMATION_DEFAULT_PROPS}>
            <img src={Icon} className="reason__icon" />
          </ScrollAnimation>
          <ScrollAnimation {...SCROLL_ANIMATION_DEFAULT_PROPS}>
            <div className="reason__title">5. {t('Get access to a special offer')}</div>
          </ScrollAnimation>
          <ScrollAnimation {...SCROLL_ANIMATION_DEFAULT_PROPS}>
            <div className="reason__subtitle">
              <p>
                {t('Try MealPal for just')} <span>{t('$1.99')}</span> {t('per meal for your first week.')}
              </p>
              <p>
                {t('Plus, get')} <span>{t('25% off')}</span> {t('your next 2 months.')}
              </p>
            </div>
          </ScrollAnimation>
          <ScrollAnimation {...SCROLL_ANIMATION_DEFAULT_PROPS}>
            <a href={url} className="reason__action red-cta">
              {t('Start saving on lunch today')}
            </a>
          </ScrollAnimation>
        </div>
      </div>
    </ScrollAnimation>
  )
}

export default SpecialOffer
