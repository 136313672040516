import React, { useEffect } from 'react'
import { RouteProps } from 'react-router-dom'

import FooterForm from '../footer-form/Container'
import SEO from '../seo'
import FAQs from './faqs/FAQs'
import Hero from './hero/Hero'
import Enjoy from './reasons/Enjoy'
import SaveMoney from './reasons/SaveMoney'
import SaveTime from './reasons/SaveTime'
import SpecialOffer from './reasons/SpecialOffer'
import Support from './reasons/Support'
import RestaurantLogos from './restaurant-logos/RestaurantLogos'

const ReasonsContainer: React.FC<RouteProps<'/reasons'>> = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <SEO title="5 Reasons" />
      <Hero />
      <RestaurantLogos />
      <SaveMoney />
      <Enjoy />
      <SaveTime />
      <Support />
      <SpecialOffer />
      <FAQs />
      <FooterForm position="reasons-footer-form" />
    </>
  )
}

export default ReasonsContainer
